import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Row, Col, Card } from 'react-bootstrap'
import { GetQueryString } from '../components/census'

import './../assets/css/aritcle.css'
import './../assets/css/aritclesDetail.css'
import './../assets/css/market-cases.css'
import './../assets/css/bootstrap.min.css'

const MarketCases = props => {
  const tag = GetQueryString('tag')
  // const [ data, setData ] = React.useState([])

  const applicationScenarios = useStaticQuery(graphql`
    {
      allStrapiApplicationScenario (sort: {fields: updated_at, order: DESC}){
        nodes {
          bannerTitle
          created_at
          homePage
          id
          introduction
          order
          updated_at
          strapiId
          sort
          published_at
          homeImage {
            childImageSharp {
              fixed(width: 1600, height: 800) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          Appsmith {
            childImageSharp {
              fixed {
                base64
              }
            }
          }
        }
      }
    }
  `)
  const applicationScenariosData = applicationScenarios?.allStrapiApplicationScenario?.nodes
  const data = [ ...applicationScenariosData ]

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let common_media = document.getElementsByClassName('common-flex');
      if(tag) {
        for(let i=0; i<common_media.length;i++) {
          let name = common_media[i].getAttribute('name')
          if(name == tag) {
            window.scrollTo({
              top: common_media[i].offsetTop -150
            })
          }
        }
      }
    }
    // fetch(`${apiURL}/application-scenarios?_sort=updated_at:DESC`, {
    //   headers: { 'content-type': 'application/json' },
    // })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       return res.json()
    //     }
    //   })
    //   .then(json => {
    //     setData([ ...json, { bannerTitle: '更多案例敬请期待', homeImage: { url: '' }, Appsmith: { id: Math.random(), url: require('./../images/WechatIMG163.png') } , id: 'more-cases', tag: 'mome' } ])
    //   })
    //   .catch(err => console.log(err))
  }, [tag])
  const reset = data.sort((a,b) => a.sort - b.sort)
  let newData = props?.staticType == 'tg' ? data.filter(val=>val?.sort).sort((a,b) => a.sort - b.sort) : [ ...reset.slice(0, reset.length-1).reverse(), reset[reset.length-1] ]
  // console.log(newData, '======newData')
  return (
    <div>
        <Row  xs={1} lg={4} md={2} className="market-cases">
          {
            newData.map(item => {
              return (
                <Col style={{ padding: '1rem' }}>
                  <Link  to={`/articlesDetail/${item?.strapiId}`}>
                  <Card >
                    {
                      item?.id == 'more-cases' ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140, background: '#1885f8ad' }}>
                        <div className="more-cases-card">
                          <p>项目案例</p>
                          <p>持续更新</p>
                        </div>
                      </div> : <>
                      {/* { item?.Appsmith?.url ? <img style={props?.style ? { top: '1.4rem' } : {}} 
                        src={ item?.tag == 'mome' ? `${item.Appsmith.url}` : `${apiURL}${item.Appsmith.url}`} alt="" 
                      /> : null } */}
                      { item?.Appsmith?.childImageSharp?.fixed?.base64 ? <img style={props?.style ? { top: '1.4rem' } : {}} 
                        src={`${item?.Appsmith?.childImageSharp?.fixed?.base64}`} alt="" 
                      /> : null }
                      </>
                    }
                    <Card.Title className="text-base" style={props?.style ? { top: '11rem'} : {}}>{item?.bannerTitle}</Card.Title>
                  </Card>
                </Link>
              </Col>
              )
            })
          }
          <Col style={{ padding: '1rem' }}>
            <a  href="javascript:void(0);">
            <Card >
               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140, background: '#1885f8ad' }}>
                  <div className="more-cases-card">
                    <p>项目案例</p>
                    <p>持续更新</p>
                  </div>
                </div> 
              <Card.Title className="text-base" style={props?.style ? { top: '11rem'} : {}}>更多案例敬请期待</Card.Title>
            </Card>
          </a>
        </Col>
        </Row>
    </div>

  )
}

export default MarketCases
